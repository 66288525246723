(function () {
  'use strict';

  angular.module('module.settings').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('settings', {
      abstract: true,
      url: '/settings',
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          templateUrl: 'templates/navigation.template.html',
          resolve: {
            dataUser: [
              'AuthenticationService',
              function (AuthenticationService) {
                return AuthenticationService.getUser();
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Settings',
      },
    });

    // TODO: restore when product ranges feature implemented
    // $stateProvider.state('settings.productranges', {
    //   url: '/settings/productranges',
    //   controller: 'controller.settings.productranges.list',
    //   templateUrl: 'templates/settings.productranges.list.template.html',
    //   resolve: {
    //     dataProductRanges: function(productRangesService) {
    //       return productRangesService.listProductRanges();
    //     }
    //   },
    //   data : { pageTitle: 'Settings, Product Ranges' }
    // });
    //
    // $stateProvider.state('settings.productranges.create', {
    //   url: '/settings/productranges/new',
    //   controller: 'controller.settings.productranges.create',
    //   templateUrl: 'templates/settings.productranges.create.template.html',
    //   data : { pageTitle: 'Settings, New Product Range' }
    // });
    //
    // $stateProvider.state('settings.productranges.retrieve', {
    //   url: '/settings/productranges/:productRangeId',
    //   controller: 'controller.settings.productranges.retrieve',
    //   templateUrl: 'templates/settings.productranges.retrieve.template.html',
    //   resolve: {
    //     dataProductRange: function($stateParams, productRangesService) {
    //       return productRangesService.retrieveProductRange($stateParams.productRangeId);
    //     }
    //   },
    //   data : { pageTitle: 'Settings, Product Range Details' }
    // });
  }
})();
