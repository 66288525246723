(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteItemQuantity', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'templates/sales.quoteItem.quantity.directive.template.html',
        controllerAs: 'qtyVm',
        scope: {
          quote: '=',
          item: '=',
          customItem: '=',
        },
        controller: [
          '$scope',
          '$state',
          'QuoteItemsService',
          'PRICING_UNIT',
          'PRICING_UNIT_SUFFIX',
          controller,
        ],
        bindToController: true,
      };
    },
  ]);

  function controller($scope, $state, QuoteItemsService, PRICING_UNIT, PRICING_UNIT_SUFFIX) {
    /* jshint validthis: true */
    const $ctrl = this;
    const maxQuantity = 50;

    $ctrl.readOnly = true;
    $ctrl.pricingUnitSuffix = '';
    if ($ctrl.customItem) {
      $ctrl.pricingUnitSuffix = PRICING_UNIT_SUFFIX[$ctrl.item.metadata.pricingUnit];
    }

    $ctrl.quantityOptions = range(1, maxQuantity);
    $ctrl.setQuantity = setQuantity;

    $scope.$watch('qtyVm.quote.metadata.status', () => {
      if (
        $ctrl.quote &&
        $ctrl.quote.metadata.status === 'draft' &&
        $ctrl.quote.metadata.allowItemUpdates
      ) {
        $ctrl.readOnly = false;
      }
    });

    /**
     * Update item quantity
     *
     * @param {int} quantity
     */
    function setQuantity(quantity) {
      if ($ctrl.item.quantity === quantity) {
        return;
      }

      QuoteItemsService.updateItemQuantity($ctrl.quote, $ctrl.item, quantity, {
        customItem: $ctrl.customItem,
      });
    }
  }

  /**
   * Create an sequentially numbered array from min to max
   *
   * @param min
   * @param max
   * @returns {Array}
   */
  function range(min, max) {
    const a = [];
    for (let i = min; i <= max; i++) {
      a.push(i);
    }

    return a;
  }
})();
