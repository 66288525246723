(function () {
  'use strict';

  angular.module('module.sales').directive('ttPaymentDetails', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.quotes.payment.directive.template.html',
        controllerAs: 'termsVm',
        scope: {
          customer: '=',
          quote: '=',
        },
        controller: [
          '$scope',
          '$state',
          'QuotePaymentService',

          'QuoteFinalisationService',
          controller,
        ],
        bindToController: true,
      };
    },
  ]);

  function controller(
    $scope,
    $state,
    QuotePaymentService,

    QuoteFinalisationService,
  ) {
    /* jshint validthis: true */
    var vm = this;
    vm.save = save;
    vm.error = false;
    vm.allowEdit = vm.quote.metadata.status !== 'draft' ? false : true;

    vm.onCustomerUpdate = onCustomerUpdate;
    vm.paymentStages = vm.quote.metadata.paymentStages;

    vm.defaultPaymentTerms = vm.customer.metadata.paymentTerms;

    vm.readOnly = QuoteFinalisationService.isFinalised(vm.quote);

    vm.totalPercentage = 0;

    function save() {
      vm.error = false;
      if (vm.quote.metadata.paymentStages && vm.quote.metadata.paymentStages.length) {
        var total = 0;
        vm.quote.metadata.paymentStages.forEach(function (stage) {
          total += stage.percentage;
        });
        if (total !== 100) {
          vm.error = true;
          return;
        }
      }

      QuotePaymentService.updateCustomerPaymentStages(vm.quote, {
        paymentStages: vm.paymentStages,
        paymentTerms: vm.quote.metadata.paymentTerms,
      });
    }

    $scope.$watch('detailsVm.quote.metadata.status', function () {
      vm.finalised = QuoteFinalisationService.isFinalised(vm.quote);
    });

    function onCustomerUpdate() {
      $state.reload();
    }
  }
})();
