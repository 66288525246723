(function () {
  'use strict';

  angular
    .module('module.settings.preferences')
    .factory('CurrencyApiService', [
      '$q',
      'Notification',
      'ApiService',
      'CurrencyService',
      service,
    ]);

  function service($q, Notification, ApiService, CurrencyService) {
    return {
      update: update,
    };

    /**
     * Updates currency settings.
     *
     * @param {Object} currencySettings
     *
     * @returns {$q}
     */
    function update(currencySettings) {
      var data = {
        salesTaxType: currencySettings.salesTaxType,
        currency: currencySettings.currency,
        rate: currencySettings.rate,
        timezone: currencySettings.timezone,
        measurementType: currencySettings.measurementType,
        dimensionsUnit: currencySettings.dimensionsUnit,
        fractionalPrecision: currencySettings.fractionalPrecision,
      };

      return ApiService.put('/company/currencySettings', data).then(
        function () {
          Notification.success('Updated localisation settings');

          CurrencyService.setSettings(
            currencySettings.currency,
            currencySettings.salesTaxType,
            currencySettings.rate,
            currencySettings.timezone,
            currencySettings.measurementType,
            currencySettings.dimensionsUnit,
            currencySettings.fractionalPrecision,
          );

          return;
        },
        function () {
          Notification.error('Failed to update currency settings');

          return $q.reject();
        },
      );
    }
  }
})();
