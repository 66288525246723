(function () {
  'use strict';

  angular
    .module('module.settings.partsShop')
    .controller('controller.settings.partsShop.createUpdate', [
      '$state',
      'PartsShopApiService',
      'CurrencyService',
      'PRICING_UNIT',
      'CUSTOM_ITEM_CATEGORY_ORDER',
      'dataItem',
      controller,
    ]);

  function controller(
    $state,
    PartsShopApiService,
    CurrencyService,
    PRICING_UNIT,
    CUSTOM_ITEM_CATEGORY_ORDER,
    dataItem,
  ) {
    /* jshint validthis: true */
    const $ctrl = this;

    $ctrl.save = save;
    $ctrl.resetImage = resetImage;

    $ctrl.saving = false;
    $ctrl.pricingUnits = [
      {name: 'Each', value: PRICING_UNIT.EACH},
      {name: 'Per metre', value: PRICING_UNIT.PER_METRE},
      {name: 'Per square metre', value: PRICING_UNIT.PER_SQUARE_METRE},
      {name: 'Per litre', value: PRICING_UNIT.PER_LITRE},
      {name: 'Per kilo', value: PRICING_UNIT.PER_KILO},
    ];

    $ctrl.categories = CUSTOM_ITEM_CATEGORY_ORDER;

    $ctrl.image = null;
    $ctrl.currency = CurrencyService.getSettings();

    const newItem = dataItem === null;
    if (newItem) {
      $ctrl.item = {
        costPrice: 0,
        sellPrice: 0,
        pricingUnit: PRICING_UNIT.EACH,
        discountable: true,
        showAsDedicatedPage: false,
      };
    } else {
      $ctrl.item = dataItem;
    }

    function save() {
      $ctrl.saving = true;

      const action = newItem
        ? PartsShopApiService.create($ctrl.item, $ctrl.image)
        : PartsShopApiService.update(dataItem.id, $ctrl.item, $ctrl.image);

      action.then(
        () => $state.go('settings.partsShop', {}, {reload: true}),
        () => ($ctrl.saving = false),
      );
    }

    function resetImage() {
      $ctrl.image = null;
      $ctrl.item.imageId = null;
    }
  }
})();
