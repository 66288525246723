(function () {
  'use strict';

  angular
    .module('module.settings.pricingAndQuoting')
    .controller('controller.settings.pricingAndQuoting.customerPaymentTerms', [
      'SettingsService',
      'Notification',
      'dataSettings',
      'dataIsManager',
      controller,
    ]);

  /**
   * @param SettingsService
   * @param Notification
   * @param {Object} dataSettings
   * @param {Boolean} dataIsManager
   */
  function controller(SettingsService, Notification, dataSettings, dataIsManager) {
    /* jshint validthis: true */
    var vm = this;

    vm.save = save;
    vm.saving = false;

    vm.settings = dataSettings;
    vm.managerUser = dataIsManager;
    vm.paymentStages = dataSettings.settings.paymentStages;
    vm.allowEdit = true;
    vm.applyPaymentStagesToExistingCustomers = false;

    /**
     * Save settings
     */
    function save() {
      if (vm.paymentStages.length) {
        var total = 0;
        vm.paymentStages.forEach(function (stage) {
          total += stage.percentage;
        });
        if (total !== 100) {
          Notification.error('Stage payments must add up to 100%');
          return;
        }
      }

      vm.saving = true;
      SettingsService.updatePaymentStages(
        {
          paymentStages: vm.paymentStages,
          applyToCustomers: vm.applyPaymentStagesToExistingCustomers,
        },
        false,
      )
        .then(function () {
          return SettingsService.updateSettings(vm.settings);
        })
        .finally(function () {
          vm.saving = false;
        });
    }
  }
})();
