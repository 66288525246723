(function () {
  'use strict';

  angular
    .module('module.settings.preferences')
    .factory('ExternalApiApiService', [
      '$q',
      'Notification',
      'ApiService',
      'AuthenticationService',
      service,
    ]);

  function service($q, Notification, ApiService, AuthenticationService) {
    return {
      generateApiKey: generateApiKey,
      hasApiKey: hasApiKey,
      getWebhookDetails: getWebhookDetails,
      updateWebhookUrl: updateWebhookUrl,
    };

    function generateApiKey() {
      return ApiService.post('/apiManagement/generateKey', null).then(
        function (response) {
          // flush user response
          AuthenticationService.getUser(true);

          return response.data.apiKey;
        },
        function () {
          Notification.error('Failed to generate API key');

          return $q.reject(null);
        },
      );
    }

    function hasApiKey() {
      return AuthenticationService.getUser(true).then(function (user) {
        return user.metadata.hasApiKey;
      });
    }

    function getWebhookDetails() {
      return ApiService.get('/apiManagement/webhook').then(function (response) {
        return {
          webhookUrl: response.data.webhookUrl,
          isBusinessPilotEnabled: response.data.isBusinessPilotEnabled,
        };
      });
    }

    function updateWebhookUrl(webhookUrl, businessPilotEnabled = false) {
      const requestData = {
        webhookUrl,
        isBusinessPilotEnabled: businessPilotEnabled,
      };
      return ApiService.put('/apiManagement/webhook', requestData).then(
        function () {
          Notification.success('Updated webhook details');
        },
        function () {
          Notification.error('Failed to update webhook details');

          return $q.reject(null);
        },
      );
    }
  }
})();
