(function () {
  'use strict';

  angular.module('module.settings').service('RepriceModalService', ['$q', 'ModalService', service]);

  function service($q, ModalService) {
    return {
      showModal: showModal,
      showModalPromise: showModalPromise,
    };

    function showModal(message, onConfirm) {
      showModalPromise(message).then(function () {
        if (typeof onConfirm === 'function') {
          onConfirm();
        }
      });
    }

    function showModalPromise(message) {
      return ModalService.showModal({
        controller: 'controller.settings.pricingAndQuoting.pricing.reprice.modal',
        templateUrl: 'templates/settings.pricingAndQuoting.pricing.reprice.modal.template.html',
        controllerAs: 'modalVm',
        inputs: {
          message: message,
        },
      }).then(function (modal) {
        // open modal
        modal.element.parent().find('#reprice-modal').modal();
        return modal.close.then(function (result) {
          if (!result) {
            return $q.reject();
          }
        });
      });
    }
  }
})();
