(function () {
  'use strict';

  angular
    .module('module.settings.pricingAndQuoting')
    .directive('ttPricingSettingsMarkup', directive);

  /**
   * Init directive
   */
  function directive() {
    return {
      restrict: 'E',
      templateUrl: 'templates/settings.pricingAndQuoting.pricing.markup.directive.template.html',
      controllerAs: 'markupVm',
      scope: {
        settings: '=',
      },
      controller: controller,
      bindToController: true,
    };
  }

  function controller() {
    /* jshint validthis: true */
    var vm = this;
    var firstJT = vm.settings.productSettings[0].markupPerJobType;

    vm.addAMarkup = vm.settings.useMarkup !== 'no';
    vm.sameForAllProducts = vm.settings.useMarkup === 'yes';

    // There's no value in the tri-state to say if we're varying by work type so
    // if we're varying by product, check each object to see if there are values
    vm.varyByJobType = false;
    if (vm.settings.useMarkup === 'products') {
      vm.settings.productSettings.forEach(function (product, index) {
        Object.keys(product.markupPerJobType).forEach(function (key) {
          if (product.markupPerJobType[key]) {
            vm.varyByJobType = true;
          }
        });
      });
    }

    // If we're varing by job type then we have useMarkup set to 'products' but
    // they may all be identical
    if (vm.varyByJobType) {
      vm.sameForAllProducts = true;
      vm.settings.productSettings.forEach(function (product, index) {
        if (JSON.stringify(product.markupPerJobType) !== JSON.stringify(firstJT)) {
          vm.sameForAllProducts = false;
        }
      });
    }

    vm.update = function () {
      // Set the tri-state
      vm.settings.useMarkup = 'no';
      if (vm.addAMarkup) {
        vm.settings.useMarkup = 'products';
        if (vm.sameForAllProducts && !vm.varyByJobType) {
          vm.settings.useMarkup = 'yes';
        }
      }

      if (vm.settings.useMarkup === 'products') {
        vm.settings.markup = 0;
      }

      vm.settings.productSettings.forEach(function (product, index) {
        // Reset product markups if not relevant
        if (vm.varyByJobType) {
          product.markup = 0;
        } else {
          Object.keys(product.markupPerJobType).forEach(function (key) {
            product.markupPerJobType[key] = 0;
          });
        }

        // If varying by job type but not by product, copy to all products
        if (vm.varyByJobType && vm.sameForAllProducts && index !== 0) {
          product.markupPerJobType = JSON.parse(JSON.stringify(firstJT));
        }
      });
    };
  }
})();
