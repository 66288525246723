(function () {
  'use strict';

  angular
    .module('module.settings.preferences')
    .controller('controller.settings.preferences.externalApi', [
      'ExternalApiApiService',
      'dataWebhook',
      controller,
    ]);

  function controller(ExternalApiApiService, dataWebhook) {
    /* jshint validthis: true */
    var $ctrl = this;
    $ctrl.saving = false;
    $ctrl.updateWebhookUrl = updateWebhookUrl;

    $ctrl.webhookUrl = dataWebhook.webhookUrl;

    function updateWebhookUrl() {
      $ctrl.saving = true;
      ExternalApiApiService.updateWebhookUrl($ctrl.webhookUrl).finally(function () {
        $ctrl.saving = false;
      });
    }
  }
})();
