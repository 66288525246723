(function () {
  'use strict';

  const module = angular.module('module.settings.partsShop');
  module.config(['$stateProvider', createStates]);

  function createStates($stateProvider) {
    $stateProvider.state('settings.partsShop', {
      url: '/parts-shop',
      views: {
        'content@': {
          templateUrl: 'templates/settings.partsShop.list.template.html',
          controller: 'controller.settings.partsShop.list',
          controllerAs: '$ctrl',
        },
      },
      ncyBreadcrumb: {
        label: 'Parts Shop',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Parts Shop',
        },
      },
    });

    $stateProvider.state('settings.partsShop.create', {
      url: '/new',
      templateUrl: 'templates/settings.partsShop.createUpdate.template.html',
      controller: 'controller.settings.partsShop.createUpdate',
      controllerAs: '$ctrl',
      resolve: {
        dataItem: () => null,
      },
      ncyBreadcrumb: {
        label: 'New',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Parts Shop | New',
        },
      },
    });

    $stateProvider.state('settings.partsShop.update', {
      url: '/{id}',
      templateUrl: 'templates/settings.partsShop.createUpdate.template.html',
      controller: 'controller.settings.partsShop.createUpdate',
      controllerAs: '$ctrl',
      resolve: {
        dataItem: [
          'PartsShopApiService',
          '$stateParams',
          function (ApiService, $stateParams) {
            return ApiService.retrieve($stateParams.id);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Update',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Parts Shop | Update',
        },
      },
    });
  }
})();
