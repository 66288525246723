(function () {
  'use strict';

  angular.module('module.settings.retailerGlass').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('settings.retailerGlass', {
      url: '/my-glass',
      views: {
        'content@': {
          controller: 'controller.settings.retailerGlass',
          controllerAs: 'glassVm',
          templateUrl: 'templates/settings.retailerGlass.template.html',
        },
      },
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'My Glass',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | My Glass',
        },
      },
    });
  }
})();
