(function () {
  'use strict';

  angular
    .module('module.settings.preferences')
    .factory('accountMonitoringService', ['$q', 'Notification', 'ApiService', service]);

  function service($q, Notification, ApiService) {
    return {
      updateAccountMonitoring: updateAccountMonitoring,
    };

    /**
     * @param {boolean} activeStatus
     * @returns {$q}
     */
    function updateAccountMonitoring(activeStatus) {
      var data = {
        accountMonitoringEnabled: activeStatus,
      };
      return ApiService.put('/accountMonitoring', data).then(
        function (data) {
          Notification.success('Updated account monitoring');
          return data;
        },
        function () {
          Notification.error('Failed to update account monitoring');
          return $q.reject();
        },
      );
    }
  }
})();
