(function () {
  'use strict';

  angular
    .module('module.settings.preferences')
    .controller('controller.settings.preferences.accountMonitoring', [
      'accountMonitoringService',
      'dataProfile',
      'activeStatus',
      function (accountMonitoringService, dataProfile, activeStatus) {
        /* jshint validthis: true */
        var $ctrl = this;
        $ctrl.saving = false;
        $ctrl.user = dataProfile || {};
        $ctrl.user.settings = $ctrl.user.settings || {};
        $ctrl.user.settings.accountMonitoringEnabled =
          activeStatus !== undefined ? activeStatus : false;

        $ctrl.save = function () {
          $ctrl.saving = true;
          accountMonitoringService
            .updateAccountMonitoring($ctrl.user.settings.accountMonitoringEnabled)
            .finally(function () {
              $ctrl.saving = false;
            });
        };
      },
    ]);
})();
