(function () {
  'use strict';

  angular.module('module.settings.advanced.documentStore').config(['$stateProvider', createStates]);

  function createStates($stateProvider) {
    $stateProvider.state('settings.advanced.documentStore', {
      url: '/document-store',
      templateUrl: 'templates/settings.advanced.documentStore.list.template.html',
      controller: 'controller.settings.advanced.documentStore.list',
      controllerAs: '$ctrl',
      resolve: {
        dataDocuments: [
          'DocumentStoreApiService',
          function (DocumentStoreApiService) {
            return DocumentStoreApiService.list().then(function (documents) {
              return documents;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Document Store',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Document Store',
        },
      },
    });

    $stateProvider.state('settings.pricingAndQuoting.documentStore', {
      url: '/document-store',
      templateUrl: 'templates/settings.advanced.documentStore.list.template.html',
      controller: 'controller.settings.advanced.documentStore.list',
      controllerAs: '$ctrl',
      resolve: {
        dataDocuments: [
          'DocumentStoreApiService',
          function (DocumentStoreApiService) {
            return DocumentStoreApiService.list().then(function (documents) {
              return documents;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Document Store',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Pricing & Quoting | Document Store',
        },
      },
    });
  }
})();
