(function () {
  'use strict';

  angular.module('module.sales').component('ttFinaliseModalSelectDocuments', {
    templateUrl: 'templates/sales.quotes.finalise.modal.selectDocuments.component.template.html',
    controller: ['$q', 'DocumentStoreApiService', 'DocumentStoreUploadService', controller],
    controllerAs: '$ctrl',
    bindings: {
      quote: '<',
      onChange: '<',
      onChangeSelected: '&',
    },
  });

  function controller($q, DocumentStoreApiService, DocumentStoreUploadService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.$onInit = onInit;
    $ctrl.onUpload = onUpload;
    $ctrl.uploading = false;
    $ctrl.uploadedDocuments = [];
    $ctrl.upload = upload;

    function onInit() {
      DocumentStoreApiService.list($ctrl.quote).then(function (response) {
        $ctrl.documents = response.documents;
        $ctrl.manufacturerDocuments = response.manufacturerDocuments;
        $ctrl.coverPagePdf = response.coverPagePdf;
      });
    }

    function onUpload(uploadedDocuments) {
      if (uploadedDocuments && uploadedDocuments.length > 0) {
        uploadedDocuments.forEach(function (document) {
          document.selected = true;
          document.tempFile = true;
          $ctrl.uploadedDocuments.push(document);
        });
      }
    }

    function upload() {
      var promises = [];

      angular.forEach($ctrl.uploadedDocuments, function (document) {
        document.selected = true;
        document.tempFile = true;
        promises.push(
          DocumentStoreUploadService.upload(document).then(function (response) {
            response.selected = true;
            $ctrl.documents = $ctrl.documents.concat(response);
            $ctrl.onChangeSelected();
            $ctrl.uploadedDocuments = [];
          }),
        );
      });
    }
    $ctrl.onChangeSelected = function () {
      var selectedDocuments = $ctrl.documents.concat($ctrl.manufacturerDocuments).filter(selected);
      $ctrl.onChange(selectedDocuments);
    };

    function selected(document) {
      return document.selected;
    }
  }
})();
