(function () {
  'use strict';

  angular.module('module.settings.pricingAndQuoting').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('settings.pricingAndQuoting', {
      url: '/pricing-and-quoting',
      abstract: true,
      views: {
        'content@': {
          controller: [
            '$scope',
            'NavigationService',
            function ($scope, NavigationService) {
              $scope.navigation = NavigationService.getPricingAndQuotingNavItems();
            },
          ],
          templateUrl: 'templates/settings.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Pricing and Quoting',
      },
    });

    $stateProvider.state('settings.pricingAndQuoting.pricing', {
      url: '/pricing',
      controller: 'controller.settings.pricingAndQuoting.pricing',
      controllerAs: 'pricingVm',
      templateUrl: 'templates/settings.pricingAndQuoting.pricing.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Pricing',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Pricing And Quoting | Pricing',
        },
      },
    });

    $stateProvider.state('settings.pricingAndQuoting.quote', {
      url: '/quote',
      controller: 'controller.settings.pricingAndQuoting.quote',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.pricingAndQuoting.quote.template.html',
      resolve: {
        dataSettings: [
          'QuoteSettingsApiService',
          function (QuoteSettingsApiService) {
            return QuoteSettingsApiService.getSettings();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Quote Settings',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Pricing & Quoting | Quote Settings',
        },
      },
    });

    $stateProvider.state('settings.pricingAndQuoting.customerPaymentTerms', {
      url: '/customer-payment-terms',
      controller: 'controller.settings.pricingAndQuoting.customerPaymentTerms',
      controllerAs: 'termsVm',
      templateUrl: 'templates/settings.pricingAndQuoting.customerPaymentTerms.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
        dataIsManager: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.isManagerUser();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Payment Terms',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Pricing & Quoting | Payment Terms',
        },
      },
    });

    $stateProvider.state('settings.pricingAndQuoting.contract', {
      url: '/contract',
      controller: 'controller.settings.pricingAndQuoting.contractSettings',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.pricingAndQuoting.contractSettings.template.html',
      resolve: {
        dataContractSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true).then(function (response) {
              return response.settings.contract;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Contract',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Pricing & Quoting | Contract',
        },
      },
    });
  }
})();
