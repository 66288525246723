(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.quotes.itemFromPartsShop', [
      '$state',
      '$scope',
      'CustomItemService',
      'JobTypeApiService',
      'SettingsService',
      'CurrencyService',
      'PRICING_UNIT',
      'PRICING_UNIT_SUFFIX',
      'dataItem',
      'dataQuote',
      'dataItemTemplate',
      controller,
    ]);

  function controller(
    $state,
    $scope,
    CustomItemService,
    JobTypeApiService,
    SettingsService,
    CurrencyService,
    PRICING_UNIT,
    PRICING_UNIT_SUFFIX,
    dataItem,
    dataQuote,
    dataItemTemplate,
  ) {
    /* jshint validthis: true */
    const $ctrl = this;

    $ctrl.updateCheckboxes = function () {
      if (!$ctrl.item.includeInQuote) {
        $ctrl.item.dedicatedPageQuote =
          $ctrl.item.dedicatedPageSurveyorReport =
          $ctrl.item.dedicatedPageContract =
            false;
      } else {
        $ctrl.item.dedicatedPageQuote =
          $ctrl.item.dedicatedPageSurveyorReport =
          $ctrl.item.dedicatedPageContract =
            true;
      }
    };
    $ctrl.save = save;
    $ctrl.selectedJobType = selectedJobType;
    $scope.itemActiveName = 'items';

    $ctrl.saving = false;
    $ctrl.pricingUnits = PRICING_UNIT;
    $ctrl.pricingUnitSuffix = PRICING_UNIT_SUFFIX;

    $ctrl.quote = dataQuote;
    $ctrl.template = dataItemTemplate;

    // Note: this is for the breadcrumbs. Don't use $scope for anything else
    $scope.quote = dataQuote;
    $scope.customer = dataQuote.metadata.customer;
    $scope.customerfullname = dataQuote.metadata.customer.name.fullName;
    $scope.customersampleId = dataQuote.metadata.customer.id;
    $scope.hashLocation = 'quotes';
    $scope.customer.metadata = {
      name: $scope.customer.name,
    };

    $ctrl.image = null;
    $ctrl.currency = CurrencyService.getSettings();

    if (dataItem) {
      $ctrl.mode = 'update';
      $ctrl.item = dataItem.metadata;
    } else {
      $ctrl.mode = 'create';
      $ctrl.item = {
        quantity: 1,
        name: dataItemTemplate.name,
        sellPrice: dataItemTemplate.sellPrice,
        discountable: dataItemTemplate.discountable,
        notes: dataItemTemplate.notes ? dataItemTemplate.notes : '',
        jobTypeId: null,
        image: dataItemTemplate.imageUrl,
        pricingUnit: dataItemTemplate.pricingUnit,
        multiple: 1,
        includeInQuote: true,
        location: '',
        dedicatedPageContract: true,
        dedicatedPageQuote: true,
        dedicatedPageSurveyorReport: true,
      };
    }

    $ctrl.loadingSettings = true;
    SettingsService.getSettings().then((data) => {
      $ctrl.showIncludeInQuote = !data.settings.showItemPricing;
      $ctrl.loadingSettings = false;
    });

    $ctrl.showQuantity = $ctrl.item.pricingUnit === PRICING_UNIT.EACH;
    $ctrl.readOnly = dataQuote.metadata.status !== 'draft';

    function selectedJobType() {
      if ($ctrl.item.jobTypeId) {
        JobTypeApiService.list().then((jobTypes) => {
          $ctrl.jobTypes = jobTypes;
          const jobType = $ctrl.jobTypes.find((job) => job.id === $ctrl.item.jobTypeId);
          $ctrl.item.jobTypeId = jobType;
        });
      } else {
        $ctrl.item.jobTypeId = null;
      }
    }

    function save() {
      $ctrl.saving = true;
      $ctrl.item.jobTypeId =
        $ctrl.item.jobTypeId && $ctrl.item.jobTypeId.id ? $ctrl.item.jobTypeId.id : null;
      const action = dataItem
        ? CustomItemService.updateItemFromPartsShop(dataQuote.id, dataItem.id, $ctrl.item)
        : CustomItemService.createFromPartsShop(dataQuote.id, dataItemTemplate.id, $ctrl.item);

      action.then(
        () => $state.go('sales.quotes.retrieve', {quoteId: dataQuote.id}),
        () => ($ctrl.saving = false),
      );
    }
    JobTypeApiService.list().then((jobTypes) => {
      $ctrl.jobTypes = jobTypes;
      if (dataItem && dataItem.metadata && dataItem.metadata.jobTypeId) {
        const jobType = $ctrl.jobTypes.find((job) => job.id === dataItem.metadata.jobTypeId);
        $ctrl.jobTypeName = jobType.name;
        $ctrl.item.jobTypeId = jobType;
      }
    });
  }
})();
