(function () {
  'use strict';

  angular
    .module('module.settings.preferences')
    .factory('SalespersonSettingsApiService', [
      '$q',
      'Notification',
      'ApiService',
      'SettingsService',
      service,
    ]);

  function service($q, Notification, ApiService, SettingsService) {
    return {
      getSettings: getSettings,
      updateSettings: updateSettings,
    };

    function getSettings() {
      return SettingsService.getSettings(true).then(function (response) {
        return {
          segmentCustomers: response.settings.segmentCustomers,
        };
      });
    }

    function updateSettings(settings) {
      return ApiService.put('/settings/salesperson', settings).then(
        function () {
          Notification.success('Updated salesperson settings');
        },
        function () {
          Notification.error('Failed to update salesperson settings');

          return $q.reject(null);
        },
      );
    }
  }
})();
