(function () {
  'use strict';

  angular
    .module('module.settings.pricingAndQuoting')
    .directive('ttRepriceModal', ['RepriceModalService', directive]);

  function directive(RepriceModalService) {
    return {
      restrict: 'A',
      scope: {
        message: '@',
        onConfirm: '<',
        skipModalIf: '<',
      },
      link: link,
    };

    function link(scope, element) {
      element.on('click', showModal);

      function showModal() {
        if (scope.skipModalIf) {
          scope.onConfirm();

          return;
        }

        RepriceModalService.showModal(scope.message, scope.onConfirm);
      }
    }
  }
})();
