(function () {
  'use strict';

  angular.module('module.settings.advanced').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('settings.advanced', {
      url: '/advanced',
      abstract: true,
      views: {
        'content@': {
          controller: [
            '$scope',
            'NavigationService',
            function ($scope, NavigationService) {
              $scope.navigation = NavigationService.getAdvancedSettingsNavItems();
            },
          ],
          templateUrl: 'templates/settings.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Advanced',
      },
    });

    $stateProvider.state('settings.advanced.suppliers', {
      url: '/suppliers',
      controller: 'controller.settings.advanced.suppliers',
      templateUrl: 'templates/settings.advanced.suppliers.template.html',
      resolve: {
        dataSettingsSuppliers: [
          'SettingsSuppliersService',
          function (SettingsSuppliersService) {
            return SettingsSuppliersService.SuppliersRetrieve();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Suppliers',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Suppliers',
        },
      },
    });

    $stateProvider.state('settings.advanced.profileSuites', {
      url: '/profile-suites',
      controller: 'controller.settings.advanced.profileSuites.list',
      templateUrl: 'templates/settings.advanced.profileSuites.list.template.html',
      resolve: {
        dataProfileSuites: [
          '$stateParams',
          'ProfileSuitesService',
          function ($stateParams, ProfileSuitesService) {
            return ProfileSuitesService.ListProfileSuites();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Profile Suites',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Profile Suites',
        },
      },
    });

    $stateProvider.state('settings.advanced.materialTypes', {
      url: '/material-types',
      controller: 'controller.settings.advanced.materials',
      templateUrl: 'templates/settings.advanced.materialTypes.template.html',
      resolve: {
        dataSettingsMaterials: [
          'SettingsMaterialsService',
          function (SettingsMaterialsService) {
            return SettingsMaterialsService.MaterialsRetrieve();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Material Types',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Material Types',
        },
      },
    });

    $stateProvider.state('settings.advanced.productTypes', {
      url: '/product-types',
      controller: 'controller.settings.advanced.productTypes',
      templateUrl: 'templates/settings.advanced.productTypes.template.html',
      resolve: {
        dataSettingsMaterials: [
          'SettingsProductTypesService',
          function (SettingsProductTypesService) {
            return SettingsProductTypesService.ProductTypesRetrieve();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Product Types',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Product Types',
        },
      },
    });

    $stateProvider.state('settings.advanced.productStyles', {
      url: '/product-styles',
      controller: 'controller.settings.advanced.productStyles',
      controllerAs: 'stylesVm',
      templateUrl: 'templates/settings.advanced.productStyles.template.html',
      resolve: {
        dataProductTypes: [
          'TypesService',
          function (TypesService) {
            // TODO: this should probably be a method on the TypesService
            return TypesService.List('products').then(function (types) {
              return types.sort(function (a, b) {
                return a.name.localeCompare(b.name);
              });
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Product Styles',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Product Styles',
        },
      },
    });

    $stateProvider.state('settings.advanced.glass', {
      url: '/glass',
      controller: 'controller.settings.advanced.glass',
      controllerAs: 'glassVm',
      templateUrl: 'templates/settings.advanced.glass.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Glass',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Glass',
        },
      },
    });

    $stateProvider.state('settings.advanced.orderCharges', {
      url: '/order-charges',
      controller: 'controller.settings.advanced.orderCharges',
      controllerAs: 'orderChargesVm',
      templateUrl: 'templates/settings.advanced.orderCharges.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Order Charges',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Advanced | Order Charges',
        },
      },
    });
  }
})();
