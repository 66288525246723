(function () {
  'use strict';

  angular.module('module.settings.preferences').config(['$stateProvider', config]);

  function config($stateProvider) {
    // Add state for preferences
    $stateProvider.state('settings.preferences', {
      url: '/preferences',
      abstract: true,
      views: {
        'content@': {
          controller: [
            '$scope',
            'NavigationService',
            function ($scope, NavigationService) {
              $scope.navigation = NavigationService.getPreferencesNavItems();
            },
          ],
          templateUrl: 'templates/settings.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Preferences',
      },
    });

    $stateProvider.state('settings.preferences.salesperson', {
      url: '/salesperson',
      controller: 'controller.settings.preferences.salesperson',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.preferences.salesperson.template.html',
      resolve: {
        dataSettings: [
          'SalespersonSettingsApiService',
          function (SalespersonSettingsApiService) {
            return SalespersonSettingsApiService.getSettings();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Salesperson',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Preferences | Salesperson',
        },
      },
    });

    $stateProvider.state('settings.preferences.localisation', {
      url: '/localisation',
      controller: 'controller.settings.preferences.localisation',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.preferences.localisation.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Localisation',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Preferences | Localisation',
        },
      },
    });

    $stateProvider.state('settings.preferences.manufacturers', {
      url: '/manufacturers',
      controller: 'controller.settings.preferences.manufacturers',
      controllerAs: 'manufacturersVm',
      templateUrl: 'templates/settings.preferences.manufacturers.template.html',
      resolve: {
        dataSettings: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings(true);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Manufacturers',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Preferences | Manufacturers',
        },
      },
    });

    $stateProvider.state('settings.preferences.vipSupport', {
      url: '/vip-support',
      controller: 'controller.settings.preferences.accountMonitoring',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.preferences.accountMonitoring.template.html',
      resolve: {
        dataProfile: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.getUser();
          },
        ],
        activeStatus: [
          'SettingsService',
          function (SettingsService) {
            return SettingsService.getSettings().then(function (settings) {
              return settings.settings.accountMonitoringEnabled;
            });
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'VIP Support',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Preferences | VIP Support',
        },
      },
    });

    $stateProvider.state('settings.preferences.externalApi', {
      url: '/external-api',
      controller: 'controller.settings.preferences.externalApi',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.preferences.externalApi.template.html',
      resolve: {
        dataWebhook: [
          'ExternalApiApiService',
          function (ExternalApiApiService) {
            return ExternalApiApiService.getWebhookDetails();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'External Api',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Preferences | External Api',
        },
      },
    });

    $stateProvider.state('settings.preferences.businessPilot', {
      url: '/business-pilot',
      controller: 'controller.settings.preferences.businessPilot',
      controllerAs: '$ctrl',
      templateUrl: 'templates/settings.preferences.businessPilot.template.html',
      resolve: {
        dataWebhook: [
          'ExternalApiApiService',
          function (ExternalApiApiService) {
            return ExternalApiApiService.getWebhookDetails();
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Business Pilot',
      },
      data: {
        meta: {
          titleSuffix: '| Settings | Preferences | Business Pilot',
        },
      },
    });
  }
})();
