(function () {
  'use strict';

  angular.module('module.sales').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider

        .state('sales.customers', {
          abstract: true,
          ncyBreadcrumb: {
            skip: true,
          },
        })

        .state('sales.customers.list', {
          url: '/customers',
          views: {
            'content@': {
              controller: 'controller.sales.customers.list',
              templateUrl: 'templates/sales.customers.list.template.html',
              resolve: {
                dataUsers: [
                  'CustomerSegmentationService',
                  function (SegService) {
                    return SegService.getVisibleUsers();
                  },
                ],
                dataCustomers: [
                  'CustomersService',
                  'dataUsers',
                  function (CustomersService) {
                    // will be auto-restricted if segmenting, should default to all for managers
                    return CustomersService.listCustomers();
                  },
                ],
              },
            },
          },
          ncyBreadcrumb: {
            label: 'My Customers',
          },
          data: {
            meta: {
              titleSuffix: '| My Customers',
            },
          },
        })

        .state('sales.customers.retrieve', {
          url: '/customers/:customerId',
          views: {
            'content@': {
              controller: 'controller.sales.customers.retrieve',
              controllerAs: 'customerVm',
              templateUrl: 'templates/sales.customers.retrieve.template.html',
              resolve: {
                dataCustomer: [
                  '$stateParams',
                  'CustomersService',
                  function ($stateParams, CustomersService) {
                    return CustomersService.retrieveCustomer($stateParams.customerId);
                  },
                ],
              },
            },
          },
          ncyBreadcrumb: {
            parent: 'sales.customers.list',
            label: '{{hashLocation}} for {{customerfullname }}',
          },
          data: {
            meta: {
              titleSuffix: '| Customer Details',
            },
          },
        });
    },
  ]);
})();
